import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ITopic } from 'app/shared/model/topic.model';
import { getEntities as getTopics } from 'app/entities/topic/topic.reducer';
import { getEntityNewsFeed, createEntitynewsFeed, deleteNewsFeedEntity } from 'app/entities/news-feed/news-feed.reducer';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { Container, Row, Col, Button, Label, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { JhiItemCount, JhiPagination, Translate, getSortState, logDebug } from 'react-jhipster';
import InspirationUpdate from './inspirations-update';
import NewsFeedUpdate from './newsFeed-update';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';

const NewsFeedPage: React.FC = () => {
  const account = useAppSelector(state => state.authentication.account);
  const [selectedNewsFeedId, setSelectedNewsFeedId] = useState(null);
  const [newsFeedData, setNewsFeedData] = useState([]);
  const loading = useAppSelector(state => state.newsFeed.loading);
  const [showView, setShowView] = useState('list');
  const baseImageUrl = '/image';
  const newsFeedList = useAppSelector(state => state.newsFeed.entity);
  const dispatch = useAppDispatch();
  const id = account.id;
  const navigate = useNavigate();
  const totalItems = useAppSelector(state => state.newsFeed.totalItems);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, 20, 'id', 'desc'), location.search)
  );

  useEffect(() => {
    if (account) {
      dispatch(
        getEntityNewsFeed({
          id: account.id,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
  }, [account, paginationState]);

  useEffect(() => {
    setNewsFeedData(newsFeedList);
  }, [newsFeedList]);
  const handleSubmit = async () => {
    await dispatch(
      getEntityNewsFeed({
        id: account.id,
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
    setShowView('list');
  };
  const deleteNewsFeed = async id => {
    await dispatch(deleteNewsFeedEntity(id));
    await dispatch(
      getEntityNewsFeed({
        id: account.id,
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
    // setNewsFeedData(newsFeedList);
  };
  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  function formatDate(dateString) {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (inputDate >= currentDate) {
      const options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };

      return 'Today ' + inputDate.toLocaleTimeString('en-US', options as Intl.DateTimeFormatOptions);
    } else {
      const oneDayAgo = new Date(inputDate);
      oneDayAgo.setUTCDate(inputDate.getUTCDate() - 1);

      const options = {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };

      return oneDayAgo.toLocaleString('en-US', options as Intl.DateTimeFormatOptions);
    }
  }
  return (
    <Container className="news-page card meeting-border">
      {showView == 'edit' && <NewsFeedUpdate inspirationId={selectedNewsFeedId} handleSubmit={handleSubmit} />}
      {showView == 'add' && <NewsFeedUpdate inspirationId={selectedNewsFeedId} handleSubmit={handleSubmit} />}
      {loading ? (
        <Spinner className="body-loader-icon"></Spinner>
      ) : (
        <>
          <Row className="page-header">
            <Col md="6" className="heading">
              <h1>Interest / Newsfeed</h1>
            </Col>
            <Col md="6" className="add-btn">
              {showView !== 'add' && (
                <div className="news-add-btn">
                  <FontAwesomeIcon icon="plus" />
                  &nbsp;
                  <Label
                    className="add-new-btn"
                    onClick={() => {
                      setShowView('add');
                      setSelectedNewsFeedId(null);
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                  >
                    Add new news
                  </Label>
                </div>
              )}
            </Col>
          </Row>
          <Row className="news-list">
            {account ? (
              Array.isArray(newsFeedData) && newsFeedData.length > 0 ? (
                newsFeedData.map(newsfeed => (
                  <Col md="4" key={newsfeed.id}>
                    <div className="news-item">
                      <div className="news-text">{newsfeed.description}</div>
                      <div className="news-topic">{newsfeed.topics}</div>

                      <Col md={12} className="news-img">
                        {newsfeed?.imageName ? (
                          newsfeed.imageName.endsWith('.mp4') ? (
                            <video controls className="mb-4 p-0">
                              <source src={`${baseImageUrl}/${newsfeed.imageName}`} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img className="img-thumbnail mb-4 p-0" src={`${baseImageUrl}/${newsfeed.imageName}`} alt="Image" />
                          )
                        ) : null}
                      </Col>
                      <div className="news-link">
                        <a href={`${newsfeed.link}`}>{newsfeed.link}</a>
                      </div>
                      <Row className="news-time-edit">
                        <Col md="8">{<div className="news-time">{formatDate(newsfeed.createdDate)}</div>}</Col>
                        <Col md="4" className="edit-btn">
                          {/* <Button tag={Link} to={`/inspirations/${inspiration.id}/edit`} color="primary" size="sm" data-cy="entityEditButton"> */}
                          <Button
                            onClick={() => {
                              setShowView('edit');
                              setSelectedNewsFeedId(newsfeed.id);
                              window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                            color="primary"
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <FontAwesomeIcon icon="pencil-alt" />{' '}
                            <span className="d-none d-md-inline">{/* <Translate contentKey="entity.action.edit">Edit</Translate> */}</span>
                          </Button>
                        </Col>
                      </Row>
                      <Button className="news-dlt-btn" onClick={() => deleteNewsFeed(newsfeed.id)}>
                        <FontAwesomeIcon icon="trash" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span> */}
                      </Button>
                    </div>
                  </Col>
                ))
              ) : (
                <div className="connection-tab-gif">
                  <Row>
                    <div className="col-md-6">
                      {/* <h4>Build Your Professional Network</h4> */}
                      <p>
                        Stay up-to-date with all the latest news and trends in your industry with our News Feed section! With regular
                        updates on industry developments, emerging technologies and innovative strategies, you'll be in the know on
                        everything that matters. So keep checking back for the latest stories and insights - your success is our top
                        priority!
                      </p>
                    </div>
                    <div className="col-md-6">
                      <img src="content/images/news_feed.png" />
                    </div>
                  </Row>
                </div>
              )
            ) : (
              <Spinner color="primary" />
            )}
          </Row>
          {totalItems ? (
            <div className={newsFeedData && newsFeedData.length > 0 ? '' : 'd-none'}>
              <div className="justify-content-center d-flex">
                <JhiItemCount
                  page={paginationState.activePage}
                  total={totalItems}
                  itemsPerPage={paginationState.itemsPerPage}
                  i18nEnabled
                />
              </div>
              <div className="justify-content-center d-flex">
                <JhiPagination
                  activePage={paginationState.activePage}
                  onSelect={handlePagination}
                  maxButtons={5}
                  itemsPerPage={paginationState.itemsPerPage}
                  totalItems={totalItems}
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </Container>
  );
};

export default NewsFeedPage;
